import './App.css';
import React, { useState, useEffect } from 'react';
import DateRangePicker from './components/DateRangePicker';
import { motion } from 'framer-motion';

function App() {
  const [weatherData, setWeatherData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false); // Состояние для темной темы

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Применение темной темы при изменении состояния
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  async function fetchData(start = '', end = '') {
    const url = new URL('https://miniapi.egeoweather.ru/api/weather');
    if (start && end) {
      url.searchParams.append('start', start);
      url.searchParams.append('end', end);
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setWeatherData(data);
      } else {
        console.error('Invalid data format:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleApplyDateRange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
    fetchData(start, end);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode); // Переключение темной темы
  };

  return (
    <div className="App">
      <h1>EGeo-Weather</h1>
      <DateRangePicker onApply={handleApplyDateRange} />
      <ul>
        {Array.isArray(weatherData) ? weatherData.map((item, index) => (
          <motion.li
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="weather-item"
          >
            <h2>Температура: {item.temperature}°C</h2>
            <p>Влажность: {item.humidity}%</p>
            <p>Давление: {item.pressure} hPa</p>
            <p className="timestamp">Дата: {new Date(item.timestamp).toLocaleString()}</p>
          </motion.li>
        )) : <li>No data available</li>}
      </ul>
    </div>
  );
}

export default App;
